import React, { useState, useEffect, useMemo } from "react";
import {
  Button,
  TextField,
  Grid,
  Box,
  Typography,
  Card,
  Checkbox,
  Accordion,
  AccordionSummary,
  AccordionDetails,
} from "@mui/material";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import config from "./Utils/config";
import { PrintFeature } from "./Utils/PrintFeature";
import {
  calculateDistancesAndDurationsTeams,
  geocodeAddress,
} from "./Utils/CalculateDistance";
import showToast from "./Utils/showToast";
import Loader from "./Utils/loader";
import { ModeOfTravel } from "@mui/icons-material";
import { loadGoogleMapsAPI } from "./Utils/LoadGoogleMap";

function ViewAllTeams() {
  const [expandedPanels, setExpandedPanels] = useState([]);
  const [teams, setTeams] = useState([]);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);
  const [locations, setLocations] = useState([]);
  const [displayedData, setDisplayedData] = useState([]);
  const [distances, setDistances] = useState({});
  const [durations, setDurations] = useState({});
  const [selectedTeamsForteamId, setSelectedTeamsForteamId] = useState([]);
  const [googleMapsLoaded, setGoogleMapsLoaded] = useState(false);
  const [selectedDate, setSelectedDate] = useState(
    new Date().toISOString().split("T")[0]
  );

  //loading
  useEffect(() => {
    const loadAPI = async () => {
      try {
        await loadGoogleMapsAPI(); 
        setGoogleMapsLoaded(true); 
      } catch (err) {
        setError(err);
      }
    };
    loadAPI();
  }, [googleMapsLoaded]);

  useEffect(() => {
    const fetchTeamsAndUpdateLocations = async () => {
      setLoading(true); 
      setError(null);
      try {
        const token = localStorage.getItem("token");
        const response = await fetch(
          `${config.apiBaseUrl}ContractTimeTeamInfoes/getContractTeamsData`,
          {
            method: "GET",
            headers: {
              "Content-Type": "application/json",
              Authorization: `Bearer ${token}`,
            },
          }
        );
        if (!response.ok) {
          throw new Error("Network response was not ok");
        }
        const data = await response.json();
        setTeams(data);
        setDisplayedData(data);

        // Updating locations with geocoding after fetching teams
        const updatedLocations = await Promise.all(
          data.map(async (team) => {
            const fullAddress = `${team.address}, ${team.city}, ${team.zip}`;
            try {
              const coordinates = await geocodeAddress(fullAddress);
              return {
                teamId: team.teamId, 
                address: team.address,
                zip: team.zip,
                city: team.city,
                lat: coordinates.lat,
                lng: coordinates.lng,
              };
            } catch (error) {
              console.error(error);
              return null;
            }
          })
        );

        // Filter out null locations (in case geocoding fails)
        const validLocations = updatedLocations.filter(
          (location) => location !== null
        );

        // Group the locations by teamNo
        const groupedLocations = validLocations.reduce((acc, location) => {
          if (!acc[location.teamId]) {
            acc[location.teamId] = [];
          }
          acc[location.teamId].push(location);
          return acc;
        }, {});

        setLocations(groupedLocations); 
      } catch (error) {
        setError("Error fetching teams: " + error.message);
      } finally {
        setLoading(false);
      }
    };

    // Fetch teams and locations on page load
    fetchTeamsAndUpdateLocations();
  }, []);

  // Group teams by teamNo
  const groupTeamsByteamId = (data) => {
    const groupedTeams = {};
    data.forEach((team) => {
      if (!groupedTeams[team.teamId]) {
        groupedTeams[team.teamId] = [];
      }
      groupedTeams[team.teamId].push(team);
    });

    // Add a flag for groups with only one team
    Object.keys(groupedTeams).forEach((teamId) => {
      if (groupedTeams[teamId].length === 1) {
        groupedTeams[teamId].forEach((team) => {
          team.isSingle = true; 
        });
      }
    });

    return groupedTeams;
  };

  // useMemo for recalculating the groupedTeams
  const groupedTeams = useMemo(
    () => groupTeamsByteamId(displayedData),
    [displayedData]
  );

  const handlePrint = () => {
    if (Object.keys(groupedTeams).length === 0) {
      showToast({
        type: "error",
        message: "There is no data to print.",
      });
      return;
    }
    const dataToPrint =
      selectedTeamsForteamId.length > 0
        ? selectedTeamsForteamId
        : [].concat(...Object.values(groupedTeams)); 
    const printData = dataToPrint.map((team, index) => {
      const teamId = team.teamId; 
      const teamDistance =
        distances[teamId] && distances[teamId][index] !== undefined
          ? distances[teamId][index]
          : "N/A"; 
      const teamDuration =
        durations[teamId] && durations[teamId][index] !== undefined
          ? durations[teamId][index]
          : "N/A"; 
      return {
        ...team, 
        distance: teamDistance, 
        duration: teamDuration,
      };
    });

    // Now, instead of passing objects for distances and durations, pass arrays
    const printDistances = dataToPrint.map((team, index) => {
      const teamId = team.teamId;
      return distances[teamId] ? distances[teamId][index] : "N/A";
    });

    const printDurations = dataToPrint.map((team, index) => {
      const teamId = team.teamId;
      return durations[teamId] ? durations[teamId][index] : "N/A";
    });
    PrintFeature(printData, printDistances, printDurations);

    showToast({
      type: "success",
      message: "PDF generated successfully!",
    });
  };

  // Handle date change
  const handleDateChange = (e) => {
    const newDate = e.target.value;
    setSelectedDate(newDate);
    if (newDate) {
      const filtered = teams.filter((team) => team.entryDate === newDate);
      setDisplayedData(filtered);
    } else {
      setDisplayedData(teams);
    }
  };

  const handleDistance = async (teamId) => {
    const locationData = await extractLocations(locations[teamId]);
    calculateDistancesAndDurationsTeams(
      locationData,
      setDistances,
      setDurations,
      teamId
    );
  };

  // Extract locations (geocode addresses) for all teams by teamId
  const extractLocations = async (teamData) => {
    if (!Array.isArray(teamData)) {
      console.error("Invalid team data: expected an array.");
      return [];
    }
    const updatedLocations = await Promise.all(
      teamData.map(async (team) => {
        const fullAddress = `${team.address}, ${team.city}, ${team.zip}`;
        try {
          // Geocode the address to get lat/lng coordinates
          const coordinates = await geocodeAddress(fullAddress);
          return {
            teamId: team.teamId,
            address: team.address,
            zip: team.zip,
            city: team.city,
            lat: coordinates.lat,
            lng: coordinates.lng,
          };
        } catch (error) {
          console.error("Error geocoding address:", error);
          return null; 
        }
      })
    );
    // Filter out any null locations (in case geocoding failed)
    return updatedLocations.filter((location) => location !== null);
  };

  // Handle select/deselect a teamNo for checkbox
  const handleteamNoCheckboxChange = (e, teamId) => {
    const groupedTeams = groupTeamsByteamId(displayedData);
    const teamGroup = groupedTeams[teamId];
    if (e.target.checked) {
      setSelectedTeamsForteamId((prevSelected) => [
        ...prevSelected,
        ...teamGroup,
      ]);
    } else {
      setSelectedTeamsForteamId((prevSelected) =>
        prevSelected.filter((team) => !teamGroup.includes(team))
      );
    }
  };

  // initialize the map
  const mapInitializer = (teamId, distances, durations) => {
    if (googleMapsLoaded && locations && locations[teamId]) {
      // Create the map container ID
      const mapContainerId = `map-${teamId}`;
      const mapContainer = document.getElementById(mapContainerId);
      if (!mapContainer) return;
      // Create a new map centered at the first location (you can adjust the initial zoom and center later)
      const map = new window.google.maps.Map(mapContainer, {
        mapId: config.googleMapId,
        zoom: 10,
      });
      // Define bounds to dynamically adjust map view based on the markers
      const bounds = new window.google.maps.LatLngBounds();
      locations[teamId].forEach((location, index) => {
        // Add each location as a marker
        const marker = new window.google.maps.Marker({
          position: { lat: location.lat, lng: location.lng },
          map: map,
          title: `${location.address}, ${location.city}, ${location.zip}`,
        });
        bounds.extend(marker.getPosition());
        // Add an info window for each marker
        const distance = distances[teamId] ? distances[teamId][index] : "N/A";
        const duration = durations[teamId] ? durations[teamId][index] : "N/A";
        const infoWindowContent = `
        <div>
          <h3>${location.address}, ${location.city}</h3>
          <p>Distance: ${distance} km</p>
          <p>Duration: ${duration} min</p>
        </div>
      `;
        const infoWindow = new window.google.maps.InfoWindow({
          content: infoWindowContent,
        });
        marker.addListener("click", () => {
          infoWindow.open(map, marker);
        });
      });
      map.fitBounds(bounds);
    }
  };

  // handle accordion change
  const handleAccordionChange = (panel) => (event, isExpanded) => {
    setExpandedPanels((prevExpanded) => {
      if (isExpanded) {
      
        return [...prevExpanded, panel];
      } else {
     
        return prevExpanded.filter((p) => p !== panel);
      }
    });
  };

  // Trigger map initialization whenever distances or durations are updated
  useEffect(() => {
    Object.keys(distances).forEach((teamId) => {
      mapInitializer(teamId, distances, durations);
    });
  }, [distances, durations]);

  console.log(groupedTeams);

  if (loading) {
    return <Loader isLoading={loading} />;
  }

  if (error) {
    return <div>Error: {error}</div>;
  }

  return (
    <Box sx={{ p: 4 }}>
      <Typography variant="h4" gutterBottom align="center" sx={{ mb: 3 }}>
        All Teams
      </Typography>

      {/* Date and Actions Section */}
      <Grid
        container
        spacing={4}
        alignItems="center"
        sx={{ mb: 4, display: "flex", justifyContent: "space-between" }}
      >
        <Grid item xs={12} sm={4} md={2}>
          <TextField
            type="date"
            value={selectedDate}
            onChange={handleDateChange}
            fullWidth
            InputLabelProps={{ shrink: true }}
            sx={{ backgroundColor: "white", maxWidth: "150px" }}
          />
        </Grid>

        <Grid item xs={12} sm={4} md={1}>
          <Button
            onClick={handlePrint}
            variant="contained"
            color="primary"
            fullWidth
            sx={{ height: "100%", maxWidth: "160px" }}
          >
            Print
          </Button>
        </Grid>
      </Grid>

      {/* Teams List Section */}
      <Box sx={{ width: "100%" }}>
        {Object.keys(groupedTeams).length === 0 ? (
          <Typography variant="h6" color="error" align="center">
            No teams available.
          </Typography>
        ) : (
          <Grid container spacing={2} justifyContent="center">
            {/* Map over teamNo and display each in an accordion */}
            {Object.keys(groupedTeams).map((teamId, index) => (
              <Grid item xs={12} sm={12} md={12} lg={12} key={index}>
                <Accordion
                  expanded={expandedPanels.includes(`panel${index}`)} 
                  onChange={handleAccordionChange(`panel${index}`)} 
                  sx={{
                    backgroundColor: expandedPanels.includes(`panel${index}`)
                      ? "0 4px 20px rgba(33, 224, 160, 0.1)"
                      : "none",
                  }}
                >
                  <AccordionSummary
                    expandIcon={
                      <ExpandMoreIcon
                        sx={{
                          color: expandedPanels.includes(`panel${index}`)
                            ? "white"
                            : "inherit", 
                        }}
                      />
                    }
                    aria-controls={`panel${index}-content`}
                    id={`panel${index}-header`}
                    sx={{
                      backgroundColor: expandedPanels.includes(`panel${index}`)
                        ? "#1976d2"
                        : "transparent", 
                      color: expandedPanels.includes(`panel${index}`)
                        ? "white"
                        : "inherit", 
                      transition: "background-color 0.3s", // Smooth transition for color change
                    }}
                  >
                    <Typography variant="h6" sx={{ flex: 1 }}>
                      <Checkbox
                        checked={selectedTeamsForteamId.some((team) =>
                          groupedTeams[teamId].includes(team)
                        )}
                        onChange={(e) => {
                          e.stopPropagation();
                          handleteamNoCheckboxChange(e, teamId);
                        }}
                        sx={{
                          mr: 1,
                          color: expandedPanels.includes(`panel${index}`)
                            ? "white"
                            : "black", 
                          "&.Mui-checked": {
                            color: "blue", 
                          },
                          "&.MuiCheckbox-root:hover": {
                            backgroundColor: "transparent", 
                          },
                        }}
                      />
                      {/* Extract and display the unique teamNo */}
                      {Array.from(
                        new Set(groupedTeams[teamId].map((team) => team.teamNo))
                      ).join(", ")}
                    </Typography>

                    <Box sx={{ display: "flex", alignItems: "center" }}>
                      {/* Only show the "Show Distance" button if the team does not have the isSingle flag */}
                      {!groupedTeams[teamId].some((team) => team.isSingle) && (
                        <Button
                          startIcon={<ModeOfTravel />}
                          onClick={(e) => {
                            e.stopPropagation(); // Prevent the accordion from closing
                            handleDistance(teamId); // Call the function with the correct teamNo
                          }}
                          sx={{
                            mr: 2,
                            color: expandedPanels.includes(`panel${index}`)
                              ? "white"
                              : "#1976d2", // Change button color when expanded
                            "&:hover": {
                              backgroundColor: expandedPanels.includes(
                                `panel${index}`
                              )
                                ? "#1976d2"
                                : "transparent", // Change button hover color
                            },
                          }}
                        >
                          Show Distance
                        </Button>
                      )}
                    </Box>
                  </AccordionSummary>

                  <AccordionDetails
                    sx={{
                      maxHeight: "500px",
                      width: "100%",
                      display: "flex",
                      padding: "30px",
                    }}
                  >
                    <Grid
                      style={{
                        overflowY: "auto",
                        maxHeight: "400px",
                        width: "50%",
                      }}
                      container
                      spacing={2}
                    >
                      {groupedTeams[teamId].map((team, teamIndex) => (
                        <Grid item xs={12} sm={12} md={12} key={teamIndex}>
                          <Card
                            style={{ width: "90%", margin: "0px auto" }}
                            sx={{
                              padding: 3,
                              boxShadow: "0 0 10px rgba(68, 129, 235, 0.7)",
                              borderRadius: 2,
                              transition:
                                "transform 0.3s, box-shadow 0.3s, background-color 0.3s",
                              backgroundColor: "#fff",
                              "&:hover": {
                                transform: "scale(1.02)",
                                boxShadow:
                                  "0 0 20px rgb(113, 144, 197), 0 0 30px rgba(147, 169, 206, 0.8)",
                              },
                            }}
                          >
                            <Typography
                              variant="h6"
                              sx={{ fontWeight: "bold", marginBottom: 1 }}
                            >
                              {team.firstName} {team.lastName}
                            </Typography>
                            <Typography
                              variant="body2"
                              sx={{ marginBottom: 1 }}
                            >
                              <b>Address:</b> {team.address}
                            </Typography>
                            <Typography
                              variant="body2"
                              sx={{ marginBottom: 1 }}
                            >
                              <b>Zip Code:</b> {team.zip}
                            </Typography>
                            <Typography
                              variant="body2"
                              sx={{ marginBottom: 1 }}
                            >
                              <b>City:</b> {team.city}
                            </Typography>

                            {/* Ternary operator to check if this is the active team */}
                            <Typography
                              variant="body2"
                              sx={{ marginBottom: 1 }}
                            >
                              <b> Distance: </b>
                              {distances[teamId] &&
                              distances[teamId][teamIndex] !== undefined
                                ? distances[teamId][teamIndex]
                                : "N/A"}{" "}
                              km
                            </Typography>
                            <Typography
                              variant="body2"
                              sx={{ marginBottom: 1 }}
                            >
                              <b> Duration: </b>
                              {durations[teamId] &&
                              durations[teamId][teamIndex] !== undefined
                                ? durations[teamId][teamIndex]
                                : "N/A"}{" "}
                              min
                            </Typography>

                            <Typography
                              variant="body2"
                              sx={{ marginBottom: 1 }}
                            >
                              <b>Date:</b> {team.date}
                            </Typography>
                          </Card>
                        </Grid>
                      ))}
                    </Grid>
                    <Box
                      id={`map-${teamId}`}
                      sx={{
                        height: "350px",
                        width: "35%",
                        margin: "0px auto",
                        position: "sticky",
                        padding: "20px",
                        zIndex: 1,
                      }}
                    />
                  </AccordionDetails>
                </Accordion>
              </Grid>
            ))}
          </Grid>
        )}
      </Box>
    </Box>
  );
}

export default ViewAllTeams;
